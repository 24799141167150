<template>
  <div class="assignment">
    <div class="dateInput">
      <div class="dateInput">
        <el-date-picker v-model="data_stamp" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp">
        </el-date-picker>
        <Page :current="taskListCuPage" :total="taskListTotal" simple @on-change="pageChange" style="margin-left: 10px" />
      </div>
      <div>
        <el-button type="primary" style="margin-right:30px" @click="toAddTask('/addTask')">新建任务</el-button>
      </div>
    </div>
    <div class="content">
      <div class="content_left">
        <div class="top">
          <div v-if="taskList.length===0" style="width:100%;height:100%;" class="flex_center_center">
            暂时没有任务
          </div>
          <div class="item" v-for="item in taskList" :key="item.id" @click="taskItemClick(item)" :class="item.id == listOperationPar.task_id ? 'active_color' : ''">
            <div class="date_box">
              <div class="avator">
                <div class="flex_center_col">
                  <el-avatar  icon="el-icon-user-solid" size="small" :src="item.patrol_user.avatar" @error="errorHandler">
                    <img :src="circleUrl"/>
                  </el-avatar>
                  <div class="name">{{item.patrol_user.name}}</div>
                </div>
                <div>
                  <span>{{item.startTime}}</span> - <span>{{item.endTime}}</span>
                </div>
              </div>
            </div>
            <div class="location">{{item.name}}</div>
            <div class="site">点位进度：{{item.patrol_site_count}}/{{item.site_count}}</div>
          </div>
        </div>
      </div>
      <div class="content_right">
        <div class="top">
          <div class="people_info" v-if="Object.keys(this.peopleInfo).length>0">
            <div class="item">
              <div class="item_title">巡检人员</div>
              <div class="item_content">{{peopleInfo.patrol_user.name}}</div>
            </div>
            <div class="item">
              <div class="item_title">巡检时间</div>
              <div class="item_content">{{peopleInfo.startTime}} - {{peopleInfo.endTime}}</div>
            </div>
            <div class="item">
              <div class="item_title">任务名称</div>
              <div class="item_content">{{peopleInfo.name}}</div>
            </div>
            <div class="item">
              <div class="item_title site">点位进度</div>
              <div class="item_content site">{{peopleInfo.patrol_site_count}}/{{peopleInfo.site_count}}</div>
            </div>
            <div class="item">
              <div class="item_title">备注</div>
              <div class="item_content">{{peopleInfo.remark||'未填写'}}</div>
            </div>
            <div>
              <el-image style="width: 80px; height: 80px;margin:10px" :src="item" :preview-src-list="fileImg" accordion :key="this" v-for="item in fileImg"></el-image>
            </div>
          </div>
          <div class="commont_list" v-if="Object.keys(this.peopleInfo).length>0" style="overflow:auto;" @scroll="load">
            <div class="commont_title">
              <i class="el-icon-edit-outline"></i> 评论列表
            </div>
            <div class="item" v-for="item in commentList" :key="item.id">
              <div class="date_box">
                <div class="avator">
                  <div class="flex_center_col">
                    <el-avatar icon="el-icon-user-solid" size="small" :src="item.create_user_avatar" @error="errorHandler">
                      <img :src="circleUrl"/>
                    </el-avatar>
                    <div class="name">{{item.create_user_name}}</div>
                  </div>
                  <div>
                    {{item.time}}
                  </div>
                </div>
              </div>
              <div class="location">{{item.remark}}</div>
              <div v-for="media in item.files" style="margin-left:20px">
                <el-image style="width: 100px; height: 100px" :src="media.thumbnail_url" v-if="media.type == 1" :preview-src-list="srcList">
                </el-image>
                <span @click="clickVideo(media.url)" style="
                            position: relative;
                            height: 100px;
                            width: 100px;
                          " v-if="media.type == 2">
                  <i class="el-icon-video-play"></i>
                  <video :src="media.url" @click="clickVideoSlef" style="height:100px;width:100px"></video>
                </span>
                <div v-if="media.type ==3"> <audio :src="media.url" controls="controls"></audio></div>
              </div>
            </div>
          </div>

          <div v-if="!peopleInfo.name" style="width:100%;height:100%;" class="flex_center_center">
            请选择需要查看的任务内容
          </div>
        </div>
        <div class="bottom">

          <!-- @node-click="infoNode" -->
          <el-tree :data="site_logs" node-key="id" :expand-on-click-node="true" @node-expand="infoNode" accordion>
            <span class="custom-tree-node" slot-scope="{ node, data }" v-if="data.index === 1">
              <div class="flex_center_bew" style="width: 100%">
                <span>{{ node.label }}</span>
                <div class="flex_center_col">
                  <span v-if="data.patrol_stamp">{{
                      (data.patrol_stamp * 1000)
                        | dateformat("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  <el-button type="warning" size="mini" v-if="data.skipped == 1" round style="margin-rigth: 20px; margin-left: 30px" plain>跳检</el-button>
                  <el-button size="mini" v-else-if="data.patrol_stamp == 0" round plain style="margin-rigth: 20px; margin-left: 30px">未检</el-button>
                  <el-button type="primary" size="mini" v-else-if="data.situation == 1" round style="margin-rigth: 20px; margin-left: 30px" plain>正常</el-button>
                  <el-button type="danger" size="mini" v-else round style="margin-rigth: 20px; margin-left: 30px" plain>异常</el-button>
                </div>
              </div>
            </span>
            <span class="custom-tree-node" slot-scope="{ node, data }" v-else-if="data.index === 2">
              <span>{{ node.label }}</span>
              <span v-if="data.patrol_stamp" style="margin-right: -280px">{{
                  (data.patrol_stamp * 1000) | dateformat("YYYY-MM-DD HH:mm")
                }}</span>
              <el-button type="danger" size="mini" v-if="data.situation == 0" round style="margin-rigth: 20px" plain>异常</el-button>
              <el-button type="primary" size="mini" v-else round style="margin-rigth: 20px" plain>正常</el-button>
            </span>
            <span class="custom-tree-node" slot-scope="{ node, data }" v-else-if="data.description||data.sdescription || data.remark || data.files || data.value">
              <div>
                <div class="query linHeight activeColor" v-if="data.sdescription">
                  <span class="after"></span>{{data.sdescription}}</div>
                <div v-if="data.value" class="linHeight activeColor">{{ data.value }}</div>
                <div v-if="data.remark">{{ data.remark }}</div>
                <div v-if="data.description">
                  <span class="dot"></span> <span style="color:#409eff">{{ data.description }}</span>
                </div>
                <div v-if="data.files">
                  <div class="mediaClass">
                    <div v-for="item in data.files" :key="item.url" style="margin: 0 20px">
                      <el-image v-if="item.type == 1" style="width: 100px; height: 100px" :src="item.thumbnail_url" :preview-src-list="srcList1" accordion></el-image>
                      <span @click="clickVideo(item.url)" style="
                            position: relative;
                            height: 100px;
                            width: 100px;
                          " v-if="item.type == 2">
                        <i class="el-icon-video-play"></i>
                        <video :src="item.url" @click="clickVideoSlef" ></video>
                      </span>
                      <el-image v-if="item.type == 4" style="width: 100px; height: 100px" :src="item.url" :preview-src-list="srcList1" accordion></el-image>
                    </div>
                  </div>
                  <div v-for="item in data.files" :key="item.url" style="margin: 20px">
                    <audio :src="item.url" v-if="item.type == 3" controls="controls"></audio>
                  </div>
                </div>
              </div>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
    <div class="popContainer" v-show="videoVisible" @click="closeVideoPage">
      <i class="el-icon-circle-close" @click="closeVideoPage"></i>
      <video class="video-js" controls preload="auto" data-setup="{}" ref="myVideo" style="width: 70vw; height: 80vh" :src="videoSrc"></video>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import { task } from '../../utils/api';
import _ from 'lodash';
export default {
  name:'assignment',
  data() {
    return {
      data_stamp: '',
      //第二天的时间戳 用于进行比较
      tomorrow_stamp: '',
      taskListPar: {
        date_stamp: '',
        scope: 2,
        page: 1,
        page_size: 10
      },
      taskListCuPage: 1,
      taskListTotal: 0,
      taskList: [],
      peopleInfo: {},
      count: 0,
      loading: false,
      listOperationPar: {
        task_id: '',
        page: 0,
        page_size: 10
      },
      listOperationParTol: 0,
      divHeight: 0,
      isLoading: true,
      commentList: [],
      commentListTotal: 0,
      srcList: [],
      videoSrc: '',
      videoVisible: false,
      patrolHistoryData: [],
      patrolHistory: {},
      rolling: true,
      site_logs: [],
      fileImg: [],
      defaultProps: {
        children: 'inspection_logs',
        label: 'name'
      },
      srcList1: [],
      isLoading: true,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
    };
  },
  created() {
    this.data_stamp = new Date(new Date().toLocaleDateString()).getTime();
  },
  mounted() {},
  components: {},

  computed: {
    noMore() {
      return this.count >= 20;
    },
    disabled() {
      return this.loading || this.noMore;
    }
  },

  methods: {
    async getTaskList() {
      const res = await this.$http.get(
        task.listTask + '?' + qs.stringify(this.taskListPar)
      );
      res.data.forEach(item => {
        item.startTime = this.$moment(
          item.prescribed_start_stamp * 1000
        ).format('HH:mm');
        item.endTime =
          item.prescribed_end_stamp > this.tomorrow_stamp
            ? '次日 ' +
              this.$moment(item.prescribed_end_stamp * 1000).format('HH:mm')
            : this.$moment(item.prescribed_end_stamp * 1000).format('HH:mm');
      });
      this.taskList = res.data;
      this.taskListTotal = res.total;
    },
    pageChange(e) {
      this.taskListPar.page = e;
      this.getTaskList();
    },
    taskItemClick(item) {
      if (this.listOperationPar.task_id === item.id) {
        return false;
      }
      this.loading = true;
      this.peopleInfo = item;
      this.listOperationPar.task_id = item.id;
      this.listOperationPar.page = 0;
      this.commentList = [];
      this.loadMore();
      this.isLoading = false;
      this.getTaskInfo();
    },
    load: _.debounce(function(e) {
      if (
        e.target.scrollTop + e.target.clientHeight ===
        e.target.scrollHeight
      ) {
        this.loadMore();
        this.isLoading = false;
      }
    }, 100),
    // 滚动加载函数
    async loadMore() {
      if (!this.isLoading) {
        return false;
      }
      this.listOperationPar.page++;
      const res = await this.$http.get(
        task.listOperation + '?' + qs.stringify(this.listOperationPar)
      );
      if (res.code === 0) {
        res.data.forEach(item => {
          item.time = this.$moment(item.create_time * 1000).format(
            'YYYY-MM-DD HH:mm'
          );
        });
        this.commentList.push(...res.data);
        this.commentListTotal = res.total;
        this.srcList = [];
        this.commentList.forEach(item => {
          item.files.forEach(fItem => {
            if (fItem.type === 1) {
              this.srcList.push(fItem.url);
            }
          });
        });
        if (this.commentListTotal !== this.commentList.length) {
          this.isLoading = true;
        }
      }
    },
    clickVideoSlef() {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
    },
    clickVideo(url) {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
      this.$refs.myVideo.src = url;
      this.$refs.myVideo.play();
      this.videoVisible = true;
    },
    closeVideoPage() {
      this.$refs.myVideo.pause();
      this.videoVisible = false;
    },
    // 这里不用做滚动加载了
    async getTaskInfo() {
      const res = await this.$http.get(
        task.taskInfo + '?id=' + this.peopleInfo.id
      );
      if (res.code === 0) {
        res.data.site_logs.forEach(item => {
          item.label = item.site.name;
          item.children = item.inspection_logs;
          item.index = 1;
          item.children.forEach(citem => {
            // 第二级
            citem.index = 2;
            citem.label = citem.inspection_name;
            citem.children = citem.option_logs.filter(
              fitem => fitem.description
            );
            if (citem.signature) {
              citem.files.push({ type: 4, url: citem.signature });
            }
            if (citem.remark) {
              citem.children.unshift({ remark: citem.remark });
            }
            citem.option_logs.forEach(lItem => {
              if (lItem.selected == 1) {
                citem.children.unshift({ sdescription: lItem.option.description });
              }
            });
            if (citem.inspection.type == 4) {
              citem.children.unshift({ value: citem.value });
            }
            if (citem.files.length > 0) {
              citem.children.push({ files: citem.files }); /*  */
            }
          });
        });
        this.site_logs = res.data.site_logs;
        this.fileImg = [];
        res.data.files.forEach(fitem => {
          this.fileImg.push(fitem.url);
        });
      }
    },
    infoNode(data, node, self) {
      if (+node.level === 2) {
        let srcList = [];
        if (
          data.children[data.children.length - 1].files &&
          data.children[data.children.length - 1].files.length > 0
        ) {
          data.children[data.children.length - 1].files.forEach(item => {
            if (item.type === 1 || item.type === 4) {
              srcList.push(item.url);
            }
          });
          this.srcList1 = srcList;
        }
      }
    },
    toAddTask(url) {
      // this.$router.push('/addTask')
      this.$router.push(url);
    },
    errorHandler() {
        return true
      }
  },
  activated() {
    if (this.$route.query.date_stamps) {
      console.log('触发');
      if (
        this.$route.query.date_stamps.some(
          item => item == this.taskListPar.date_stamp
        )
      ) {
        this.getTaskList();
      }
    }
  },
  watch: {
    data_stamp(value) {
      if (!value) {
        return false;
      }
      // this.peopleInfo={}
      this.taskListPar.date_stamp = value / 1000;
      this.tomorrow_stamp = (value + 24 * 60 * 60 * 1000) / 1000;
      this.getTaskList();
    }
  }
};
</script>
<style lang='scss' scoped>
.assignment {
  width: 1200px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
  /deep/.custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-items: center;
    min-height: 50px !important;
    .custom-tree-node-title {
      text-align: left;
      line-height: 100px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
    }
  }
  /deep/ .el-tree-node__content {
    min-height: 50px;
    height: auto;
  }
  /deep/ .input_btn {
    display: flex;
    justify-content: space-between;
  }
  /deep/ .el-input {
    width: 300px;
  }
  /deep/ .listBox {
    display: flex;
    margin-top: 8px;
  }
  .dateInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    display: flex;
    margin-top: 8px;
    width: 100%;
    .content_left {
      width: 382px;
      margin-right: 8px;
      .top {
        width: 100%;
        height: 734px;
        // padding: 0 16px;
        background: #ffffff;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: auto;
        .item {
          display: flex;
          flex-direction: column;
          // justify-content: space-around;
          height: 111px;
          width: 100%;
          border-bottom: 1px solid #f4f4f4;
          line-height: 24px;
          padding: 0 16px;
          padding-top: 10px;
          .date_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            .avator {
              display: flex;
              align-items: center;
              width: 350px;
              justify-content: space-between;
              .name {
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-left: 16px;
              }
            }
            .date {
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
            }
          }
          .location {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            padding-left: 44px;
          }
          .site {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #409eff;
            line-height: 24px;
            padding-left: 44px;
          }
        }
      }
    }
    .content_right {
      width: 778px;
      .top {
        display: flex;
        width: 778px;
        height: 259px;
        background: #ffffff;
        border-radius: 4px;
        padding: 16px;
        box-sizing: border-box;
        margin-bottom: 8px;
        .people_info {
          width: 307px;
          min-height: 227px;
          background: #ffffff;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          padding: 16px;
          margin-right: 10px;
          .item {
            display: flex;
            line-height: 24px;
            width: 275px;
            .item_title {
              width: 89px;
            }
            .item_content {
              flex: 1;
            }
            .site {
              font-weight: 500;
              color: #409eff;
              line-height: 24px;
            }
          }
        }
        .commont_list {
          width: 425px;
          height: 227px;
          background: #ffffff;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          overflow: auto;
          padding: 16px;
          .commont_title {
            height: 20px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #333333;
            line-height: 20px;
            padding-bottom: 8px;
            // position: sticky;
            top: 0;
            background-color: #ffffff;
          }
          .item {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 8px;
            .date_box {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 14px;
              .avator {
                display: flex;
                align-items: center;
                width: 393px;
                justify-content: space-between;
                margin-bottom: 8px;
                .name {
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 14px;
                  margin-left: 16px;
                }
              }
              .date {
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }
            }
            .location {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              margin: 20px;
            }
          }
        }
      }
      .bottom {
        width: 777px;
        height: 467px;
        background: #ffffff;
        border-radius: 4px;
        padding: 20px;
        overflow: auto;
      }
    }
  }
  .ivu-page-simple .ivu-page-next,
  .ivu-page-simple .ivu-page-prev {
    height: 36px;
    display: flex;
    align-items: center;
    background: #f0f2f5;
    .ivu-icon {
      line-height: 37px;
      background: #f0f2f5;
    }
  }
}
li {
  list-style: none;
}
.infinite-list-wrapper {
  height: 100px;
}
.list {
  height: 200px;
  overflow: auto;
}
div.popContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.el-icon-video-play {
  position: absolute;
  top: -260%;
  left: 27%;
  font-size: 50px;
  color: #000000;
}
.mediaClass {
  display: flex;
  align-items: center;
  height: 100px;
  margin-top: 50px;
  .el-icon-video-play {
    position: absolute;
    top: -280%;
    left: 27%;
    font-size: 50px;
    color: #000000;
  }
}
.linHeight {
  height: 50px;
  line-height: 50px;
}
.activeColor {
  color: rgb(64, 158, 255);
}
.after {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #409eff;
  border-radius: 50%;
  margin-right: 10px;
}
span video{
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
}
</style>
